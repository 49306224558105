import React, {
  Dispatch,
  useEffect,
  useReducer,
  useContext,
  createContext,
  Suspense
} from 'react';
import {Helmet} from 'react-helmet';
import VisuallyHidden from '@reach/visually-hidden';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import uuid from 'uuid/v4';

import {colors, getRandomDefaultRoomColor} from '../../styles';
import {USER_ID_KEY, MUTED_KEY} from '../../constants';
import {TVariant} from '../../types';

const Home = React.lazy(() => import('../../routes/home/Home'));
const Create = React.lazy(() => import('../../routes/create/Create'));
const Room = React.lazy(() => import('../../routes/room/Room'));

interface IAppState {
  isMuted: boolean;
  roomColor: string;
  userId: string;
  variant: TVariant;
}

type TAppActions = 'setVariant' | 'setRoomColor' | 'setUserId' | 'setIsMuted';

interface IAppReducerAction {
  type: TAppActions;
  payload: any;
}

const appReducer = (state: IAppState, action: IAppReducerAction): IAppState => {
  switch (action.type) {
    case 'setVariant': {
      return {...state, variant: action.payload === 'dark' ? 'dark' : 'light'};
    }
    case 'setRoomColor': {
      return {...state, roomColor: action.payload};
    }
    case 'setUserId': {
      return {...state, userId: action.payload};
    }
    case 'setIsMuted': {
      return {...state, isMuted: action.payload};
    }
  }

  throw new Error(`Unhandled action type: ${action.type}`);
};

interface IAppContextProps {
  state: IAppState;
  dispatch: Dispatch<IAppReducerAction>;
}

const AppContext = createContext({} as IAppContextProps);

const useAppState = () => useContext(AppContext);

const App = () => {
  const initialAppState: IAppState = {
    isMuted: window.localStorage.getItem(MUTED_KEY) === 'true',
    variant: 'light',
    roomColor: getRandomDefaultRoomColor(),
    userId: window.localStorage.getItem(USER_ID_KEY) || uuid()
  };

  const [state, dispatch] = useReducer(appReducer, initialAppState);

  useEffect(() => {
    // pre-load next expected route after mount
    if (window.location.pathname === '/') {
      import('../../routes/create/Create');
    } else if (window.location.pathname === '/create') {
      import('../../routes/room/Room');
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem(USER_ID_KEY, state.userId);
  }, [state.userId]);

  useEffect(() => {
    const storedMutedValue = state.isMuted ? 'true' : 'false';
    window.localStorage.setItem(MUTED_KEY, storedMutedValue);
  });

  useEffect(() => {
    const isDark = state.variant === 'dark';
    const color = isDark ? colors.white : colors.black;
    const backgroundColor = isDark ? colors.darkGray : colors.white;

    document.body.style.background = backgroundColor;
    document.body.style.color = color;
  }, [state.variant]);

  return (
    <AppContext.Provider value={{state, dispatch}}>
      <Helmet defaultTitle="Yap" titleTemplate="%s - Yap" />
      <div className="yap">
        <VisuallyHidden>
          <h1>Yap</h1>
        </VisuallyHidden>
        <Suspense fallback={null}>
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/create" component={Create} />
              <Route exact path="/:id" component={Room} />
            </Switch>
          </Router>
        </Suspense>
      </div>
    </AppContext.Provider>
  );
};

export {App, useAppState};
