import styled from '@emotion/styled';
import Color from 'color';

const breakpointsRaw = {
  md: 800,
  lg: 1200
};

const breakpoints = {
  md: `${breakpointsRaw.md}px`,
  lg: `${breakpointsRaw.lg}px`
};

const fontSizes = {
  sm: '0.75rem', // 12px
  md: '1rem', // 16px
  lg: '1.5rem', // 24px
  px8: '0.5rem',
  px14: '0.875rem',
  px200: '12.5rem'
};

const lineHeights = {
  sm: '1',
  md: '1.25',
  lg: '1.5',
  xl: '2.0'
};

const colors = {
  black: '#000',
  blue: '#0000ff',
  blueOnDark: '#00b7ff',
  darkGray: '#323232',
  red: '#ff0000',
  redOnDark: '#fc0065',
  roomDefault: '#00b7ff',
  white: '#fff'
};

const roomDefaultColors = [
  '#fc0065',
  '#ffc107',
  '#00f9bd',
  '#00b7ff',
  '#6633ff'
];

const getRandomDefaultRoomColor = () => {
  return roomDefaultColors[
    Math.floor(Math.random() * roomDefaultColors.length)
  ];
};

const rainbowGradient =
  'linear-gradient(270deg, #FC0065 0.08%, #FFC107 32.61%, #00F9BD 67.75%, #00B7FF 100%)';

const nativeFocuString = `
  outline-width: 5px;
  outline-offset: -2px;
  outline-style: solid;
  outline-color: Highlight;

  @media (-webkit-min-device-pixel-ratio: 0) {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }
`;

const NativeHighlight = styled.span`
  ${nativeFocuString};
`;

const NativeHighlightFocusWithin = styled.span`
  &:focus-within {
    ${nativeFocuString};
  }
`;

const ButtonAsLink = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

const getCustomColors = (color?: string) => {
  let backgroundColor = Color(colors.roomDefault);
  try {
    backgroundColor = Color(color);
  } catch (error) {
    // ignore error
  }

  const fontColor = backgroundColor.isLight() ? colors.black : colors.white;

  return {
    fontColor,
    backgroundColor: backgroundColor.hex()
  };
};

const getContrastColor = (color?: string) => {
  let startColor = Color(colors.roomDefault);
  try {
    startColor = Color(color);
  } catch (error) {
    // ignore error
  }

  return startColor.negate().hex();
};

const getRoomGradient = (color?: string) => {
  let startColor = Color(colors.roomDefault);
  try {
    startColor = Color(color);
  } catch (error) {
    // ignore error
  }

  const startGradientHex = startColor.lighten(0.2).hex();
  const endGradientHex = startColor
    .lighten(0.2)
    .rotate(-90)
    .hex();

  return `linear-gradient(90deg, ${startGradientHex}, ${endGradientHex})`;
};

export {
  breakpointsRaw,
  breakpoints,
  fontSizes,
  lineHeights,
  colors,
  roomDefaultColors,
  rainbowGradient,
  NativeHighlight,
  NativeHighlightFocusWithin,
  getCustomColors,
  getContrastColor,
  getRoomGradient,
  getRandomDefaultRoomColor,
  ButtonAsLink
};
