import {format} from 'timeago.js';

export const USER_ID_KEY = 'userId';
export const RECENT_USERNAME_KEY = 'recentUsername';
export const MUTED_KEY = 'muted';

export const getRoomUsernameKey = (roomId: string) => {
  return `${roomId}/username`;
};

export const getRoomSecretKey = (roomId: string) => {
  return `${roomId}/roomSecret`;
};

export const getUserSecretKey = (roomId: string) => {
  return `${roomId}/secret}`;
};

export const getInviteUrl = ({
  roomId,
  roomSecret
}: {
  roomId: string;
  roomSecret: string;
}) => {
  return `${getBroadcastUrl(roomId)}?invite=${roomSecret}`;
};

export const getBroadcastUrl = (roomId: string) => {
  return `${window.location.origin}/${roomId}`;
};

export const getTimestamp = () => {
  return new Date().toISOString();
};

export const getTimeDiff = (prevISO: string, nextISO: string) => {
  const prevDate = new Date(prevISO);
  const nextDate = new Date(nextISO);
  return nextDate.getTime() - prevDate.getTime();
};

export const getTimeDifferenceString = (prevISO: string) => {
  if (!prevISO) {
    return '';
  }

  const prevDate = new Date(prevISO);
  const diff = Date.now() - prevDate.getTime();
  const numMinutes = Math.floor(diff / 60e3);

  if (numMinutes <= 0) {
    return '';
  }

  return format(prevDate);
};

export const getUserActive = (prevISO?: string) => {
  if (!prevISO) {
    return false;
  }

  const prevDate = new Date(prevISO);
  const diff = Date.now() - prevDate.getTime();
  const numMinutes = Math.floor(diff / 60e3);
  return numMinutes < 5;
};

export const getHasSelection = () => {
  const windowSelection = window.getSelection();
  return windowSelection && windowSelection.toString().length > 0;
};

const alphaNumericRegex = /^[a-z0-9]$/i;
export const getIsMeta = (e: KeyboardEvent) => {
  const isAlphaNumeric = alphaNumericRegex.test(e.key);
  return !isAlphaNumeric || e.altKey || e.ctrlKey || e.metaKey;
};

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
export const API_CREATE_ROOM_URL = `${API_BASE_URL}/rooms`;
export const API_SOCKET_URL =
  process.env.REACT_APP_API_SOCKET_URL || 'ws://localhost:4000/socket';
export const EMBEDLY_API_KEY = process.env.REACT_APP_EMBEDLY_API_KEY || '';

export const getRoomMetaUrl = (id: string) => {
  return `${API_BASE_URL}/rooms/${id}/meta`;
};

export const DELAY_SPINNER_MS = 250;
export const ERROR_MODAL_DELAY_MS = 2000;
export const LARGE_FONT_MESSAGE_LIMIT = 10;
export const LARGE_FONT_DECAY_MS = 2000;
export const CHECKING_TYPING_DELAY_MS = 1000;
export const FREQUENT_MESSAGE_MS = 1500;
export const FREQUENT_MESSAGE_MULTI = 1.2;
export const FREQUENT_MESSAGE_LIMIT = 2.5;
export const MESSAGE_MAX_LENGTH = 140;
export const MESSAGE_ANIMATE_MS = 500;
export const NAME_MAX_LENGTH = 20;
export const TOPIC_MAX_LENGTH = 40;
